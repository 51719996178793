<script>
import {mapGetters} from "vuex";
import ContactForm from "../../../components/forms/contact-form.vue";
import ContactCard from "../../../components/elements/contact-card.vue";

export default {
    components: {ContactCard, ContactForm},

    computed: {
        ...mapGetters({
            loading: 'contact/loading',
            contact: 'contact/item',
            contacts: 'contact/list'
        }),

        showForm: function () {
            return this.contact.id || this.create
        }
    },

    data: function () {
        return {
            create: false,
            height: '100%'
        }
    },

    methods: {
        fetchList: function () {
            this.$store.dispatch('contact/list', {partner_id: this.$route.params.id, /*with: 'position'*/})
        },

        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        },

        emptyContact: function () {
            this.fetchList()
            this.$store.dispatch('contact/clearItem')
            this.create = false
        },
    },

    created() {
        this.fetchList()
    }
}
</script>

<template>
    <div>
        <div class="row mb-4">
            <div class="col-12 text-start">
                <b-button variant="primary" @click="create = !create">{{ $t('base.create').ucFirst() }}</b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 py-3 rounded mb-4" :class="{'bg-light': showForm}" ref="trans">
                <transition type="animation" @enter="setHeight" @after-leave="height = '100%'">
                    <contact-form v-show="showForm" :contact="contact" @saved="emptyContact"></contact-form>
                </transition>
            </div>
            <div v-if="contacts && contacts.length" class="col-12" :class="{'col-md-6 col-lg-9': showForm}">
                <b-overlay :show="loading">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-for="(contact, index) in contacts" :key="index">
                            <contact-card :contact="contact"></contact-card>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>

<style scoped>

@keyframes slide-left {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    width: 0;
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.5s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.5s ease-in;
    overflow: hidden;
}
</style>
